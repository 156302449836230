import { Component, OnInit, Renderer2 } from '@angular/core';
import { CheckInternet } from 'src/app/services/check_internet';
import { EnvService } from 'src/app/services/env.service';
declare var $: any;

@Component({
  selector: 'app-chatbot-layout',
  templateUrl: './chatbot-layout.component.html',
  styleUrls: ['./chatbot-layout.component.scss']
})
export class ChatbotLayoutComponent implements OnInit {
  is_offline = false;
  show_online_div = false;
  online_interval: any;
  unlisten: (() => void) | null = null;

  constructor(
    private _check_internet: CheckInternet,
    private renderer: Renderer2,
    private _env: EnvService
  ) {
    this._check_internet.chat_net_on.subscribe((isOnline) => {
      if (isOnline) {
        this.set_online_div();
      } else {
        this.set_offline_div();
      }
      this.is_offline = !isOnline;
    });
  }

  set_online_div() {
    if (this.is_offline) {
      this.show_online_div = true;
      this.online_interval = setTimeout(() => {
        this.show_online_div = false;
      }, this._env.internet_conn_conf.back_online_display_timer);
      $('.offline_div').removeClass('animated_offline_div');
      $('#chat_container').removeClass('pe-none');
      this.unlisten_keydown();
    }
  }

  set_offline_div() {
    this.show_online_div = false;
    clearTimeout(this.online_interval);
    $('#chat_container').addClass('pe-none');
    if (this.unlisten) {
      this.unlisten();
    }
    this.unlisten = this.renderer.listen('window', 'keydown', this.onKeydown.bind(this));
    this.close_all_modals();
  }

  onKeydown(event: KeyboardEvent) {
    event.preventDefault();
    this.capture_click_event();
  }

  ngOnDestroy() {
    this.unlisten_keydown();
  }

  unlisten_keydown(){
    if (this.unlisten) {
      this.unlisten();
      this.unlisten = null;
    }
  }


  close_all_modals() {
    if ($('.modal-backdrop'.length)) {
      $('.modal').modal('hide')
      $('.modal-backdrop').remove()
    }
  }

  ngOnInit() {
    this._check_internet.check_net_connectivity();
  }

  capture_click_event() {
    if (this.is_offline) {
      const offlineDiv = $('.offline_div');
      offlineDiv.removeClass('animated_offline_div remove_animation').addClass('animated_offline_div')
      offlineDiv.one('animationend', () => {
        offlineDiv.removeClass('animated_offline_div').addClass('remove_animation');
      });
    } else {
      this.show_online_div = false;
      clearTimeout(this.online_interval);
    }
  }

}
