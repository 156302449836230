import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EnvService } from './services/env.service';
import { Title } from '@angular/platform-browser';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'chatbot';
  constructor(
    public router: Router,
    private env:EnvService,
    private _title: Title
  ){   
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config', this.env.analytics_id, 
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
     }) 
     this._title.setTitle(env.platform_config.title);
    }
}
