import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from './env.service';
import * as internet_connection from 'is-online';

@Injectable({
    providedIn: 'root'
})
export class CheckInternet {
    is_net_on = new BehaviorSubject<boolean>(true);
    net_interval: any;
    chat_net_on = new BehaviorSubject(true);
    net_conn_observer = new Observable<any>(observer => {
        setInterval(() => {
            observer.next(this.check_net_connectivity());
        }, this._env.internet_conn_conf.net_ping_interval);
    });

    constructor(
        public _env: EnvService,
    ) {
        if (this._env.internet_conn_conf.enable) {
            this.subscribe_net_observers();
        }
    }

    check_net_connectivity() {
        (async () => {
            this.is_net_on.next(await internet_connection());
        })();
    }

    subscribe_net_observers() {
        this.net_conn_observer.subscribe();
        this.is_net_on.subscribe(res => {
            if (res) {
                clearInterval(this.net_interval);
                this.net_interval = 0;
                this.chat_net_on.next(true);
            } else {
                this.check_internet_status_for_ping_timer();
            }
        })
    }

    check_internet_status_for_ping_timer() {
        this.net_interval = setTimeout(() => {
            if(this.ret_net_connection()){
                this.chat_net_on.next(false);
            }
        }, this._env.internet_conn_conf.net_disconnection_buffer);
    }

    async ret_net_connection() {
        const is_net = await internet_connection().then((value) => { return value });
        return is_net
    }
}
