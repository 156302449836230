import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of ,throwError} from 'rxjs';
import { EnvService } from './env.service';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError,finalize } from "rxjs/operators";
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { ToastrUtilityService } from './toastr-utility.service';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptService implements HttpInterceptor {

  constructor(
    private _route:Router,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var temp = req.url.split('/');
    const info_to_pass = {
      url: this._route.url,
      IP:localStorage.getItem('user_ip') == 'null'? null:localStorage.getItem('user_ip'),
      device:localStorage.getItem('device') == 'null'? null:localStorage.getItem('device')
    }
    let authReq:any;
    var service = "/"+temp.slice(3).join("/");
		service = service.split('?')[0]; // to extract url of get-reqs eliminating query-params.
      authReq = req.clone({
        headers: req.headers.append('_info', JSON.stringify(info_to_pass))
      });

    if(req.method == 'GET'){
        return next.handle(authReq).pipe(
					catchError((err: any) => {
            if(err instanceof HttpErrorResponse) {
                this._toastr.error('','Some Internal Error Occurred', this._toastrUtility.basic_configuration);
            }
            const errorResponse = new HttpResponse({
              status: 200, // Assuming you want to return 200 status
              body: {
                errCode: 101,
                msg: err
              }
            });
            return of(errorResponse);
          }),
        )
		} else if (req.method == 'POST'){
      return next.handle(authReq).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.body.errCode != undefined) {
              if(event.body.errCode != 0){
                this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
								if(event.body.errCode == 96){
                  // localStorage.removeItem('token');
                  // this.auth.signout();
								}
							}
            }
          }),
            catchError(err => {
              if (err instanceof HttpErrorResponse) {
                if(err.status === 0){
                  // this._route.navigateByUrl('others/downtime');
                  if($('.modal-backdrop'.length)){
                    // $('.modal-backdrop').remove();
                    // $('body').removeClass('modal-open');
                  }
                }
                const errorResponse = new HttpResponse({
                  status: 200, // Assuming you want to return 200 status
                  body: {
                    errCode: 101,
                    msg: 'An error occurred'
                  }
                });
                return of(errorResponse);
              }
              return throwError(err);
            }),
            finalize(() => {
            })
          )
    }
  }
  checkLastChange(last_change){
    if(last_change){
      let time = Date.now()
      if((time-last_change)/1000/60/60 > 24){
        localStorage.setItem('last_change',Date.now().toString())
        return true
      }else{
        return false
      }
    }else{
      localStorage.setItem('last_change',Date.now().toString())
      return true
    }
  }
}
