import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatbotLayoutComponent } from './shared/layouts/chatbot-layout/chatbot-layout.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/chat',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ChatbotLayoutComponent,
    children: [
      {
        path: 'chat',
        loadChildren: () => import('./views/chatbot/chatbot.module').then(m => m.ChatbotModule)
      },
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
