import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatbotLayoutComponent } from './chatbot-layout/chatbot-layout.component';
import { CheckInternet } from 'src/app/services/check_internet';

const layouts = [ChatbotLayoutComponent];

@NgModule({
  declarations: [ChatbotLayoutComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    CheckInternet
  ],
  exports:layouts
})
export class LayoutsModule { }
