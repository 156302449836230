import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastrUtilityService {

  constructor() { }

  basic_configuration = {
    timeOut: 3000,
    closeButton: true,
    progressBar: true
  };

  time_configuration = {
    timeOut: 2000,
    closeButton: true,
    progressBar: true
  };

}
